* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: lightblue;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  font-size: 1rem;
  color: gray;
}

p {
  margin: 0;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
  gap: 1rem;
}

.flex-grow {
  flex-grow: 1;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.centred {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cursor {
  color: white;
}

.texture-selector {
  transform: scale(2);
  opacity: 0.6;
  display: flex;
  align-items: end;
  color: #272727;
}

.texture-selector img.active {
  border: 1px solid white;
}

.move-btn {
  padding: 0.4rem;
  border: 1px solid gray;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.move-btn-container {
  gap: 0.5rem;
}
